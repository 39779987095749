import axios from 'axios';

export const env = {
    produrl: "https://sims.realware.app/api/v1",
    devurl: "https://api.collaboration.realware.tech/linesight"
}
const headerConfig = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
}

export async function uploads(formData, uploadType) {
    const basicHeaders = {
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    const options = { headers: basicHeaders };
    let type = '';
    if (uploadType === 'Soft Demand') {
        type = 'softDemand'
    }
    else if (uploadType === 'Materials') {
        type = 'material'
    }
    else if (uploadType === 'Planning') {
        type = 'planning'
    }
    else if (uploadType === 'Production Schedule') {
        type = 'prodSchl'
    }
    else if (uploadType === 'Inventory') {
        type = 'inventory'
    }
    const url = env.devurl + "/excelUpload/" + type;
    return await axios.post(url, formData, options)
}
export async function login(loginData) {
    const options = { headers: headerConfig };
    const url = env.devurl + '/auth/login'
    return await axios.post(url, loginData, options)
}
export async function logout() {
    const options = { headers: headerConfig };
    const url = env.devurl + '/auth/logout'
    return await axios.get(url, options)
}
export async function inventoryUpload(data) {
    const options = { headers: headerConfig };
    const url = env.devurl + '/inventory/upload'
    return await axios.post(url, data, options)
}
export async function planningUpload(data) {
    const headerConfig1 = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
    const options = { headers: headerConfig1 };
    const url = env.devurl + '/planning/upload'
    return await axios.post(url, data, options)
}
export async function materialUpload(data) {
    const options = { headers: headerConfig };
    const url = env.devurl + '/material/upload'
    return await axios.post(url, data, options)
}
export async function prodschdlUpload(data) {
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/upload'
    return await axios.post(url, data, options)
}
export async function softDemandUpload(data) {
    const options = { headers: headerConfig };
    const url = env.devurl + '/softDemand/upload'
    return await axios.post(url, data, options)
}
export async function softDemandCalendar(siteId) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: siteId };
    const url = env.devurl + '/softDemand/getCalender'
    return await axios.get(url, options)
}
export async function getPurchaseOrdersLinkedToWorkId(siteId) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: siteId };
    const url = env.devurl + '/softDemand/getPurchaseOrdersLinkedToWorkId'
    return await axios.get(url, options)
}
export async function getOrderWiseMaterial(orderId) {
    const options = { headers: headerConfig, params: orderId };
    const url = env.devurl + '/softDemand/getOrderWiseMaterials'
    return await axios.get(url, options)
}
export async function getMaterialCategory() {
    const options = { headers: headerConfig };
    const url = env.devurl + '/material/getMaterialCategory'
    return await axios.get(url, options)
}
export async function getMaterialName(materialCategory) {
    const options = { headers: headerConfig, params: materialCategory };
    const url = env.devurl + '/material/getMaterialName'
    return await axios.get(url, options)
}
export async function supplierInventoryList(materialCategory) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: materialCategory };
    const url = env.devurl + '/inventory/getInventoryList'
    return await axios.get(url, options)
}
export async function productionRisksCalendar(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/planningProductionRisks/' + data.siteId + '/' + data.type
    return await axios.get(url, options)
}
export async function getProductionScheduleLinkedToWorkId(workId) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/softDemandDataWithProductionSchedule/' + workId
    return await axios.get(url, options)
}
export async function poList(type) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: type };
    const url = env.devurl + '/softDemand/list'
    return await axios.get(url, options)
}
export async function poAction(action) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: action };
    const url = env.devurl + '/softDemand/action'
    return await axios.get(url, options)
}
export async function poLinesList(orderNumber) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/getItemsFromPo/' + orderNumber
    return await axios.get(url, options)
}
export async function productionScheduleList() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/PurchaseOrdersInProduction';
    return await axios.get(url, options)
}
export async function planningList(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: data };
    const url = env.devurl + '/planning/getPlanningData';
    return await axios.get(url, options)
}
export async function planningTaskData(WORK001) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: WORK001 };
    const url = env.devurl + '/planning/getTaskDataForWorkId';
    return await axios.get(url, options)
}

export async function userInfo(type) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: type };
    const url = env.devurl + '/user/dropdown';
    return await axios.get(url, options)
}
export async function purchaseOrderInProduction(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: data };
    const url = env.devurl + '/prodschdl/PurchaseOrdersInProduction';
    return await axios.get(url, options)
}
export async function supplierRecomendationForMaterial(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/suppliersRecomendationForMaterial/' + data.supplierId + '/' + data.materialId + '/' + data.needByDate;
    return await axios.get(url, options)
}
export async function getSiteWiseWorkIdCount() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/softDemand/counts';
    return await axios.get(url, options)
}

export async function siteWiseProductionCount() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/counts';
    return await axios.get(url, options)
}

export async function getSoftDemandSiteWiseRisksMap() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/softDemand/getSiteWiseRisks';
    return await axios.get(url, options)
}
export async function geProdschdlSiteWiseRisksMap() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/siteWiseProductionRisks';
    return await axios.get(url, options)
}
export async function getSiteWiseRisksBar() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/softDemand/graph';
    return await axios.get(url, options)
}

export async function getprodschdlBar() {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig };
    const url = env.devurl + '/prodschdl/graph';
    return await axios.get(url, options)
}
export async function getMaterialWiseLeadDays(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: data };
    const url = env.devurl + '/softDemand/getMaterialWiseLeadDays'
    return await axios.get(url, options)
}


export async function getPlannedTaskDataForWorkId(workId) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: workId };
    const url = env.devurl + '/Planning/getTaskDataForWorkId'
    return await axios.get(url, options)
}

export async function getsoftDemandRisk(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: data };
    const url = env.devurl + '/softDemand/delay'
    return await axios.get(url, options)
}


export async function getProductionSchedularRisk(data) {
    headerConfig.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    const options = { headers: headerConfig, params: data };
    const url = env.devurl + '/prodschdl/delay'
    return await axios.get(url, options)
}
