import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import { Calendar, momentLocalizer } from 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHistory, useLocation } from 'react-router-dom';
import Datepicker from 'react-datepicker';
const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: isSelected ? '#3174ad' : '#f1c1c1', // Highlight color for selected event, default color otherwise
        borderRadius: '0px',
        opacity: isSelected ? 1 : 0.8, // Adjust opacity for selected event
        color: isSelected ? 'white' : 'black', // Set text color for better contrast
        border: 'none',
        height: '100%'
    };
    return {
        style,
    };
};
const events = [
    {
        title: 'WORKID002',
        start: new Date(2024, 1, 1), // February 14, 2024, 10:00 AM
        end: new Date(2024, 1, 3), // February 14, 2024, 12:00 PM
    },
    {
        title: 'WORKID004',
        start: new Date(2024, 1, 14), // February 14, 2024, 10:00 AM
        end: new Date(2024, 1, 18), // February 14, 2024, 12:00 PM
    },
    {
        title: 'WORKID005',
        start: new Date(2024, 1, 22), // February 15, 2024, 1:00 PM
        end: new Date(2024, 1, 24), // February 15, 2024, 4:00 PM
    },
];
const DeviationDays = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const history = useHistory();
    const location = useLocation();

    const handleEventClick = (event) => {
        history.push('/ShipmentsDeviations')
        sessionStorage.setItem("selectedWorkId", event.title)
    }
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">DEVIATED SHIPMENTS - 40</h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-12 mb-0" style={{}}>
                            <div className="row">
                                <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                            </div>
                            <div className="row mb-0">
                                {(partyType === "Linesight" || partyType === "Supplier") &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="farmer">
                                                <option value="">-Select Customer-</option>
                                            </select>

                                        </div>
                                    </div>
                                }
                                {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                    <div className='col-md-2 mb-0'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="uom">
                                                <option value="">-Select Site-</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select From Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select To Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col mb-0">
                                    <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        style={{ margin: '', height: '70vh' }}
                        eventPropGetter={eventStyleGetter}
                        views={['month']}
                        onSelectEvent={handleEventClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default DeviationDays;