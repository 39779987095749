import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { softDemandCalendar, getMaterialWiseLeadDays, getsoftDemandRisk, getPurchaseOrdersLinkedToWorkId, getOrderWiseMaterial, poAction } from '../pages/const';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Swal from 'sweetalert2';
import CalendarComponent from "./calendar"

export default function PlanningLedger() {
    const [siteId, setSiteId] = useState(sessionStorage.getItem("siteId"));
    const [siteName, setSiteName] = useState(sessionStorage.getItem("siteName"));
    const [workEvents, setWorkEvents] = useState([])
    const [offcanvas, setoffcanvas] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverContent, setPopoverContent] = useState('');

    const [selectedWorkEvent, setSelectedWorkEvent] = useState({})
    const [poList, setPoList] = useState([])
    const [clickedIndex, setClickedIndex] = useState(0);
    const [rowData, setRowData] = useState({})
    const [risks, setRisks] = useState([])
    const [riskData, setRiskData] = useState()
    const [selectedState, setSelectedState] = useState(1)
    const history = useHistory();
    const [recomendedSuppliers, setRecomendedSuppliers] = useState([])
    const [showRiskMitigations, setShowRiskMitigations] = useState(false);
    const [dataWiseMonthsDropdown, setDataWiseMonthsDropdown] = useState([]);
    const [showRecomendationsIcon, setshowRecomendationsIcon] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [riskDataList, setRiskDataList] = useState()
    const [onTrackDataList, setOnTrackDataList] = useState();
    const [materialNameSelectedToViewRisks, setMaterialNameSelectedToViewRisks] = useState('');

    let riskList = []
    let onTrackList = []
    const columns = [
        {
            name: 'Material Name',
            selector: row => row.materialName,
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
        },
        {
            name: 'UOM',
            selector: row => row.uom,
        },
        {
            name: 'NEED BY DATE',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
        },
        {
            name: 'LEAD TIME (days)',
            selector: row => row.leadDays,
            cell: row => (
                <>
                    {row.leadDays}
                    {showRecomendationsIcon === true &&
                        <i
                            className="fa fa-info-circle"
                            style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                            onClick={() => riskMitigation(row)} // Make sure to use an arrow function here
                        ></i>
                    }


                </>
            ),
        },
    ];
    const [filteredWorkOrders, setFilteredWorkOrders] = useState([]);
    const changeHandler = (event) => {
        setPoList([]);
        setRowData([]);
        console.log(event.target.value, onTrackList, onTrackDataList, "eventttt")
        setSelectedState(event.target.value)
        setFilteredWorkOrders([]);
        if (event.target.value === "1") {
            setFilteredWorkOrders(riskDataList)
        } else {
            setFilteredWorkOrders(onTrackDataList)
            setRisks([])
        }
        // filterData(workEvents);
    }
    const filterData = (dataValues) => {
        console.log(dataValues, selectedState, "valueeee")
        let data = []
        dataValues.filter((item) => {
            console.log(item.risk, "risk")
            if (item.risk === selectedState) {
                console.log(item, "itemmm")
                data.push(item)
            }
        }
        )
        console.log(data, "datttaaaa")
        // alert(JSON.stringify(data))
        setFilteredWorkOrders(data);
    }
    useEffect(() => {
        console.log("siteId", siteId)
        // setShowLoader(true)

        softDemandCalendar({ siteId: siteId }).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data.data)
                setWorkEvents(res.data.data);
                res.data.data.filter((item) => {
                    if (item.risk === 1) {
                        riskList.push(item)

                    } else {
                        onTrackList.push(item)
                        console.log(onTrackList, "onTrackList")
                    }
                })
                setRiskDataList(riskList)
                setFilteredWorkOrders(riskList)
                setOnTrackDataList(onTrackList)
                // filterData(res.data.data);
            }
        }).catch((err) => {
            console.log(err)
        });

    }, []);
    const taskcolumns = [
        {
            name: "Work ID",
            selector: "title",
            sortable: true,
        },
        {
            name: "Description",
            selector: "workDescription",
            sortable: true,
            // minWidth: '250px'
        },
        {
            name: "Start Date",
            selector: row => moment(row.start).format('DD-MMM-YYYY'),
            sortable: true,
            // minWidth: '250px'
        },
        {
            name: "End Date",
            selector: row => moment(row.end).format('DD-MMM-YYYY'),
            sortable: true,
            // minWidth: '250px'
        },
        {
            name: "Purchase Requests",
            sortable: true,
            // minWidth: '120px',
            cell: (row) =>
                <i className="fas fa-eye view-btn" onClick={() => taskClickHandler(row)}></i>

        },


    ];
    const taskClickHandler = (event) => {
        setoffcanvas(true)
        console.log(event, "eventtttt")
        setSelectedWorkEvent(event)
        getPurchaseOrdersLinkedToWorkId({ siteId: siteId, workId: event.title }).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data)
                setPoList(res.data.data);
                if (res.data.data[0].orderStatus === 'Pending') {
                    setshowRecomendationsIcon(true)
                }
                else {
                    setshowRecomendationsIcon(false)
                }
                getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber);
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    const riskMitigation = (data) => {
        setMaterialNameSelectedToViewRisks(data.materialName);
        setShowRiskMitigations(true)
        console.log(rowData, "selected dAata")
        let riskPayload = {
            materialId: data.materialId,
            supplierId: rowData.supplierId,
            leadDays: data.leadDays,
            needByDate: data.needByDate
        }
        getMaterialWiseLeadDays(riskPayload).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data.data)
                setRisks(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

        let riskDataPayload = {
            orderRecommendedDate: rowData.orderRecommendedDate,
            leadDays: data.leadDays,
            needByDate: data.needByDate
        }
        console.log(riskDataPayload, "riskkk")
        getsoftDemandRisk(riskDataPayload).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data.data)
                setRiskData(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

    }


    const closeSideOverlay = () => {
        setoffcanvas(false);
        setPopoverVisible(false);
        setPoList([]);
        setClickedIndex(0);
        setshowRecomendationsIcon(false)


        // setPoList([]);
    };

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'softDemand' });
    };

    const handleLiClickPo = (index, orderNumber, orderStatus) => {
        console.log('orderStatus', orderStatus)
        setClickedIndex(index);
        getOrderWiseMaterialData(orderNumber);
        if (orderStatus === 1) {
            setshowRecomendationsIcon(true)
        }
        else {
            setshowRecomendationsIcon(false)
        }
    }
    const getOrderWiseMaterialData = (orderId) => {
        getOrderWiseMaterial({ orderId: orderId }).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data)
                setRowData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        console.log("siteId", siteId)
        // setShowLoader(true)
        // softDemandCalendar({ siteId: siteId }).then(res => {
        //     console.log("showLoader", res)
        //     if (res.status === 200) {
        //         console.log("data111", res.data)
        //         setWorkEvents(res.data.data)
        //         // setShowLoader(false)
        //         setDataWiseMonthsDropdown(res.data.months)
        //         setCurrentDate(new Date(res.data.months[0].year, res.data.months[0].month - 1, 1));

        //     }
        // }).catch((err) => {
        //     console.log(err)
        // });

    }, []);

    const handleRecomIconClick = () => {
        console.log("rowData[0]", rowData)
        let riskPayload = {
            materialId: rowData["materialData"][0].materialId,
            supplierId: rowData.supplierId,
            leadDays: rowData["materialData"][0].leadDays,
            orderRecommendedDate: rowData.orderRecommendedDate,
            needByDate: rowData["materialData"][0].needByDate
        }
        console.log("riskPayload", riskPayload)
        getMaterialWiseLeadDays(riskPayload).then(res => {
            console.log("showLoader", res)
            if (res.status === 200) {
                setRecomendedSuppliers(res.data.data)
            }
        })
    }

    const finalizePO = (ponumber) => {
        poAction({ poNumber: ponumber, action: 'Confirmed' }).then(res => {
            if (res.status === 200) {
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "Success",
                        text: res.data.data,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setoffcanvas(false);
                            softDemandCalendar({ siteId: siteId }).then(res => {
                                console.log("showLoader", res)
                                if (res.status === 200) {
                                    console.log("data111", res.data)
                                    setWorkEvents(res.data.data)
                                    // setShowLoader(false)
                                }
                            }).catch((err) => {
                                console.log(err)
                            });

                        }
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    // }, [])
    const handleChange = (rows) => {
        // rows.selectedRows will contain the indices or keys of the selected rows
        console.log('Selected rows:', rows.selectedRows);
        // You can perform any additional logic here, such as updating state
    };
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">WORK SHEDULES AT RISK, &nbsp; &nbsp;
                        <span style={{ fontSize: 13 }}>
                            <strong>Site: </strong> <span className='textHighlighter'>{siteName}</span>
                        </span> </h2>
                </div>
                <div class="col-6">
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>

            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Work Scheduler" eventKey="Work Scheduler">
                            <div className="row">
                                <div class="col-auto">
                                    <div class="form-inline">
                                        <label for="exampleSelect" class="mr-2">Filter By:</label>
                                        <select onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">

                                            <option value={1}>At Risk</option>
                                            <option value={0}>On Track</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <DataTable
                                        columns={taskcolumns}
                                        data={filteredWorkOrders}
                                        defaultSortField="taskID"
                                        pagination
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Calendar View" eventKey="Calendar View">
                            <CalendarComponent />
                        </Tab>

                    </Tabs>

                </div>
            </div>
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "85%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase requisitions Linked to work
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>Work ID: </strong> {selectedWorkEvent.title}
                                    </div>
                                    <div className='col-3'>
                                        <strong>Start Date:</strong> {moment(selectedWorkEvent.start).subtract(1, 'day').format('DD-MMM-YYYY')}
                                    </div>
                                    <div className='col-3'>
                                        <strong>End Date:</strong> {moment(selectedWorkEvent.end).subtract(1, 'day').format('DD-MMM-YYYY')}
                                    </div>
                                    <div className='col-2' id="sideOverlayMenu">
                                        <div class="nav-item dropdown">
                                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle">Take Action</a>
                                            <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu shadow">
                                                {

                                                }
                                                <li>
                                                    <NavLink exact to="#" className="nav-link">
                                                        Chat with supplier
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink exact to="#" className="nav-link">
                                                        Edit Order
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink exact to="#" className="nav-link">
                                                        Change Order
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink exact to="#" className="nav-link" onClick={() => finalizePO(rowData.orderId)}>
                                                        Finalize Order
                                                    </NavLink>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>
                                    <div className='col-md-3' style={{ borderRight: '1px solid black' }}>
                                        <div className='row mt-4'>
                                            <ul>
                                                {poList.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className={`${clickedIndex === index ? 'PoNumberListHighlighted' : 'PoNumberList'} ${item.risk === 0 ? 'noRisk' : 'risk'}`}
                                                        onClick={() => handleLiClickPo(index, item.orderNumber, item.risk)}
                                                    >
                                                        {item.orderNumber}
                                                    </li>
                                                ))}
                                                {/* <li className="PoNumberList" style={{ color: "red" }}>POHD001</li>
                                                <li className="PoNumberList">POHD002</li>
                                                <li className="PoNumberList">POHD003</li>
                                                <li className="PoNumberList">POHD004</li>
                                                <li className="PoNumberList">POHD005</li>
                                                <li className="PoNumberList">POHD006</li> */}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='row mt-4' style={{ fontSize: 14 }}>
                                            <div className='col-3' style={{ display: "flex" }}>
                                                <strong>Supplier : </strong>&nbsp; {rowData.supplierName}
                                                {showRecomendationsIcon &&
                                                    <div class="nav-item dropdown">
                                                        <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false"
                                                            className="dropdown-toggle"
                                                            onClick={handleRecomIconClick}>
                                                            <i className="fa fa-info-circle"
                                                                style={{ fontSize: 14, marginLeft: 10, color: "green", cursor: "pointer" }} >
                                                            </i>
                                                        </a>
                                                        <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu shadow" style={{ whiteSpace: "nowrap" }}>
                                                            {recomendedSuppliers.length !== 0 && recomendedSuppliers.map((supplier) => (
                                                                <li key={supplier.supplierName}>
                                                                    <NavLink to="#" className="nav-link" onClick={() => rowData.supplierName = supplier.supplierName}>
                                                                        {supplier.supplierName} - {supplier.leadDays} days
                                                                    </NavLink>
                                                                </li>
                                                            ))
                                                            }
                                                            {/* <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP001 - 140 days
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP003 -  120 days
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP004 -  100 days
                                                            </NavLink>
                                                        </li> */}

                                                        </ul>
                                                    </div>
                                                }
                                                {/* <i
                                                    className="fa fa-info-circle"
                                                    style={{ fontSize: 14, marginLeft: 10, color: "green", cursor: "pointer" }}
                                                    onClick={() => handleInfoClick()}
                                                ></i> */}
                                            </div>
                                            {showRecomendationsIcon === false &&
                                                <div className='col-2'>
                                                    <strong>Status : </strong>
                                                    {
                                                        rowData.orderStatus === 'Confirmed' ? <span>Finalized</span> :
                                                            rowData.orderStatus === 'Pending' ? <span>Soft Demand</span> :
                                                                <span> {rowData.orderStatus}</span>
                                                    }
                                                </div>
                                            }
                                            <div className='col-3'>
                                                <strong>Confirm By:</strong> <span style={showRecomendationsIcon === true ? { color: "red" } : { color: "green" }} > {moment(rowData.orderRecommendedDate).format('DD-MMM-YYYY')}</span>
                                            </div>
                                            <div className='col-4'>
                                                <strong>Supply Site:</strong> {rowData.siteName}
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-md-12'>
                                                <DataTable
                                                    columns={columns}
                                                    data={rowData.materialData}
                                                    pagination
                                                    dense
                                                    // selectableRows // Enable row selection
                                                    selectableRowsHighlight // Highlight selected rows
                                                    highlightOnHover
                                                    striped
                                                    onSelectedRowsChange={handleChange} // Callback for handling selected row change
                                                />
                                            </div>
                                        </div>
                                        {showRiskMitigations && risks.length > 0 &&
                                            <>
                                                <div className='row mt-4 pl-3'>
                                                    <h2>
                                                        <strong>Material Name : </strong> {materialNameSelectedToViewRisks}
                                                    </h2>
                                                </div>
                                                <div className='row mt-4' style={{ fontSize: "15px" }}>
                                                    <div className='col-7'>
                                                        <div className='title row' style={{ justifyContent: "center" }}>
                                                            <h2 class="m-2 text-dark">
                                                                Risk
                                                            </h2>
                                                        </div>

                                                        {/* <div className="order-details">
                                                        <div className="detail-row">
                                                            <div className="detail-key">Order Confirmation Date</div>
                                                            <div className="detail-value">26/11/2023</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Lead Time</div>
                                                            <div className="detail-value">210 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Available Time - Installation</div>
                                                            <div className="detail-value">170 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Estimated Shipment Delay</div>
                                                            <div className="detail-value">40 days</div>
                                                        </div>
                                                    </div> */}

                                                        <tabel>
                                                            <tbody className='table table-striped' id='sideOverlaytable'>
                                                                <tr>
                                                                    <td className='header'>Confirm Order Date </td>
                                                                    <td>{riskData && moment(riskData.orderConfirmationDate).format('DD-MMM-YYYY')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Lead Time <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.leadDays}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Available Time <small>for Installation in days</small></td>
                                                                    <td>{riskData && riskData.availableTime}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Estimated Shipment Delay <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.shipmentDelay}</td>
                                                                </tr>

                                                            </tbody>
                                                        </tabel>
                                                    </div>
                                                    {risks.length > 0 &&
                                                        <div className='col-5' style={{ borderLeft: "1px solid #666" }}>
                                                            <div className='title row' style={{ justifyContent: "center" }}>
                                                                <h2 class="m-2 text-dark">
                                                                    RISK MITIGATION
                                                                </h2>
                                                            </div>
                                                            <p style={{ marginTop: 10 }}>
                                                                Recommended suppliers with lower Lead Time to get on-time delivery
                                                            </p>
                                                            {/* <ol className='ml-0' style={{ lineHeight: 2, fontSize: 13 }} >
                                                            {risks.map((item) => (
                                                                <li><strong>{item.preferredSupplierId}</strong> </li>
                                                            ))}
                                                        </ol> */}
                                                            <table class="table table-condensed">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Supplier</strong></td>
                                                                        <td><strong>OnHand QTY</strong></td>
                                                                        <td><strong>Lead Days</strong></td>
                                                                    </tr>
                                                                    {risks.map((item) => (
                                                                        <tr>
                                                                            <td>{item.supplierName}</td>
                                                                            <td>{item.inventory} {item.uom}</td>
                                                                            <td>{item.leadDays}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
