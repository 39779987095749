import React, { useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const ProductForecast = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const changeHandler = (event) => {
        //Update Graph Parameters
    }
    // const sites = {
    //     finalizedDemands: [102, 102, 101, 134, 190, 130, 120, 102, 102, 101, 134, 190, 130, 120],
    //     softDemands: [400, 410, 405, 400, 420, 415, 390, 400, 410, 405, 400, 420, 415, 390],
    //     months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    // }
    const sites = props.softDemandBarChart;
    let options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ["POs Finalized", "Soft Demands"],
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            bottom: '0%'
        },
        grid: {

            left: 40,
            top: 10,
            right: 35,
            bottom: 60,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: sites.months,
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Months',
                nameLocation: 'middle',
                nameGap: 30
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Materials Count',
                nameLocation: 'middle',
                nameGap: 50
            }
        ],
        series: [

            {
                name: "POs Finalized",
                type: 'bar',
                barWidth: '15%',
                //stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: sites.finalizedDemands
            },
            {
                name: 'Soft Demands',
                type: 'bar',
                barWidth: '15%',
                //stack: 'total',
                label: {
                    show: false
                },
                emphasis: {
                    focus: 'series'
                },
                data: sites.softDemands

            },

        ],
    };

    return (
        <div className="Temp">
            {/* {props.data.xAxis.length>0 ? */}
            <div className='row' style={{ marginBottom: 15 }}>
                <div className='col-md-4'>
                    <div class="form-inline">
                        <h2 class="m-0 text-dark" style={{ display: 'inline' }}>Overall Material Forecast &nbsp; &nbsp;
                            {showLoader ?
                                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                                    {indicatorEl} {/* renders only while loading */}
                                </section> : ""
                            }
                        </h2>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <div class="form-inline">
                                <label for="exampleSelect" class="mr-2">Filter By:</label>
                                <select disabled onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">
                                    <option>Material Category</option>
                                    <option value={12}>Category 1</option>
                                    <option value={6}>Category 2</option>
                                    <option value={18}>Category 3</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div class="form-inline">
                                <select disabled onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">
                                    <option>Material</option>
                                    <option value={6}>Steel</option>
                                    <option value={18}>Air Coolers</option>
                                    <option value={12}>Bath Fits</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="form-inline">
                                <select onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">
                                    <option value={12}>Next 12 Months (default)</option>
                                    <option value={6}>Next 6 Months</option>
                                    <option value={18}>Next 18 Months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            />
        </div>
    );
}
export default ProductForecast;